import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Headline from '../../components/headline';

const Programas = ({ data }) => (
  <Layout>
    <SEO
      title="#TeEntapamosConAmor"
      description="En AMVI, Amamos Vivir Cada Día, A.C. tenemos niños con cáncer que juntan
        tapitas para solventar gastos que requiere su tratamiento: pasajes,
        comidas de su cuidador primario durante hospitalizaciones, alimentos
        específicos que deben consumir, medicamentos y pomadas que su centro de
        salud no cubre, etc."
    />
    <Image fluid={data.headerImage.childImageSharp.fluid} />
    <Container>
      <h1>
        #Te
        <wbr />
        Entapamos
        <wbr />
        Con
        <wbr />
        Amor
      </h1>
      <h3>Objetivo</h3>
      <p>
        En AMVI, Amamos Vivir Cada Día, A.C. tenemos niños con cáncer que juntan
        tapitas para solventar gastos que requiere su tratamiento: pasajes,
        comidas de su cuidador primario durante hospitalizaciones, alimentos
        específicos que deben consumir, medicamentos y pomadas que su centro de
        salud no cubre, etc.
      </p>
      <p>
        Las tapitas las reciben los niños directamente en su casa o en el caso
        de ser escuelas y juntar más de 60 bolsas grandes de basura (1.30x.50cm
        aproximadamente.); mandamos al camión que las compra y las mamas se
        encargan de ir a recoger el dinero directamente.
      </p>
      <h3>Mito y realidad</h3>
      <p>
        Es un mito el tema de "MIL TAPAS UNA QUIMIO": en toda la república no
        existe una fundación o empresa que de un medicamento de quimioterapia
        por mil tapas (existen varios medicamentos de quimioterapia), todas las
        fundaciones las destinan a los gastos de los tratamientos de manera
        general.
      </p>
      <p>
        En AMVI procuramos las tapas y se entregan directo a los niños. El kilo
        de tapitas la pagan en $3.00 y un kilo es una bolsa del OXXO llena. Así
        que requerimos cientos de kilos cada mes pero es una ayuda increíble
        para las familias porque es dinero que generamos entre todos que
        finalmente era basura
      </p>
      <h3>¿Que tipo de tapas necesitamos?</h3>
      <p>
        Todas las que sean de plástico; Todas las que te imagines siempre y
        cuando sean de plástico: pueden ser de bebidas (refrescos, leche, jugos,
        agua), garrafones, aceites, suavizantes, cremas, shampos, detergentes,
        limpiadores de cocina, etc.
      </p>
      <h3>Convenios con escuelas</h3>
      <p>Daremos el ejemplo de una escuela con 700 alumnos.</p>
      <p>
        Esta escuela entregara cada 60 días dos kilos de tapitas por alumno.
      </p>
      <p>Esto daría un total de 1400 kilos cada 60 días.</p>
      <p>
        Si la escuela está dentro de Toluca y Metepec el camión recolector no
        nos cobra por la recolección, el kilo de tapitas lo pagan en $3.00.
      </p>
      <p>De este modo tendremos un remanente de $4,200.</p>
      <p>
        Si la escuela está fuera de la ciudad de Toluca o Metepec debemos de
        contratar un flete que nos cobra $1500 aproximadamente.
      </p>
      <p>
        El kilo de tapitas lo pagan en $3,por 1,400 kilos tendremos un pago de
        $4,200. A esto le restamos $1500 del flete quedaran $2700 de remanente.
      </p>
      <p>
        Cada mes se asignará un pequeño diferente y su mamá se encargará de
        generar la logística para recolectar las tapas y cobrar los remanentes.
      </p>
      <p>
        Para garantizar llegar a esta meta es muy importante vincular la
        recolección de tapitas de cada alumno con una materia escolar, de otra
        manera al dejarlo libre para los papás y alumnos es muy probable que
        únicamente obtengamos el 30% de los remanentes antes descritos
      </p>
    </Container>
  </Layout>
);

Programas.propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.object.isRequired,
  }).isRequired,
};

export default Programas;

const Image = styled(Img)`
  margin-bottom: 3rem;
`;

const Programs = styled.div`
  display: grid;
  grid-template-areas: 1fr 1fr;
`;

const Card = styled.div``;

export const query = graphql`
  query {
    headerImage: file(relativePath: { regex: "/bottle-caps.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
